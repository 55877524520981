<template>
  <div class="create-content-main">
    <div class="create-form">
      <div class="p-4">
        <div class="mb-4 row">
          <h2
            class="col-sm-12 col-md-6 col-lg-6 text-truncate-mess font-title font-weight-bold"
          >
            コンテンツ表示確認
          </h2>
          <div
            class="col-sm-12 col-md-6 col-lg-6 tex-left text-md-right text-sm-left"
          >
            <button v-on:click="gotoDashboard()" class="button-back-dashboard">
              戻る
            </button>
          </div>
        </div>
        <div class="row pt-3 mb-4">
          <div class="row-input col-sm-4 col-md-4 col-lg-6">
            <label class="w-100 font-weight-bold pl-0">スラグ</label>
            <multiselect
              v-model="slagSelect"
              tag-placeholder="閲覧付与スラグを追加してください"
              class="col-sm-12 col-lg-12 px-0"
              placeholder="閲覧付与スラグを追加してください"
              label="slag_name_en"
              track-by="slag_name_en"
              :options="listSlagOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
            >
              <template v-slot:no-options> リストは空です </template>
            </multiselect>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-4 mb-4">
          <b-button
            v-on:click.prevent="getListContents()"
            variant="info"
            class="mr-4"
            >絞り込み検索</b-button
          >
          <b-button
            variant="secondary"
            class="mr-4"
            v-on:click="resetFieldSearch()"
            >リセット</b-button
          >
        </div>
        <div
          class="title-content-list"
          v-for="(item, index) in listContent.data"
          :key="index"
        >
          <div class="title-content-list-item" style="word-wrap: break-word">
            <div class="header-item d-flex">
              <div class="header-item-title customContent">
                {{ item.title }}
              </div>
              <div>
                <b-button
                  class=""
                  href="#"
                  variant="primary"
                  style="
                    margin-left: 1%;
                    background-color: #00b797;
                    border-color: #00b797;
                  "
                  v-on:click="detailContent(item.id)"
                >
                  <span class="" style="white-space: nowrap">編集</span>
                </b-button>
              </div>
            </div>
            <div class="content-item customContentNote">
              <div
                v-for="(ele, index) in item.chapters"
                :key="index"
                class="d-flex flex-direction-column align-items-center mb-4"
              >
                <div class="header-item-title customChapter">
                  <span>ー</span>
                  <span style="margin-left: 2px">{{ ele.title }}</span>
                </div>
                <div>
                  <b-button
                    class=""
                    href="#"
                    variant="primary"
                    style="background-color: #00b797; border-color: #00b797"
                    v-on:click="detailChapter(item.id)"
                  >
                    <span class="" style="white-space: nowrap">編集</span>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-center position-relative"
          v-if="listContent.last_page"
        >
          <CPagination
            v-if="listContent.total"
            :activePage.sync="page"
            :pages="listContent.last_page"
            align="center"
          />
          <!-- <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "CreateCategory",
  data() {
    return {
      listSlagSelect: [],
      listSlagOptions: [],
      slagSelect: null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      page: 1,
    };
  },
  created() {
    const data = {
      shop_id: this.shop_id,
    };
    this.getALLlistSlag(data);
    this.getListContents(1);
  },
  computed: {
    ...mapGetters(["success", "message", "error", "listContent", "listSlag"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        this.isLoading = false;
      }
    },
    page() {
      this.getListContents(this.page);
    },
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name_en: item.slag_name_en,
        slag_url: item.url,
        slag_description: item.description,
      }));
    },
    listContent() {
      this.page = this.listContent.current_page;
    },
  },
  methods: {
    ...mapActions({
      getListContent: "getListContent",
      getALLlistSlag: "getALLlistSlag",
    }),
    getListContents(page) {
      this.listSlagSelect = [];
      if (page === undefined) {
        page = this.page;
      }
      if (this.slagSelect !== null) {
        this.slagSelect.map((item) => {
          this.listSlagSelect.push(item.slag_id);
        });
      }
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
          limit: this.limit,
          slag_id: this.listSlagSelect,
        },
      };
      this.getListContent(formData);
    },
    detailContent(idContent) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Edit Content Change"
          : "Edit Content Change domain",
        params: { id: idContent, shopId: this.$route.params.shopId },
      });
    },
    detailChapter(idContent) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Table Of ContentEdit"
          : "Table Of ContentEdit domain",
        params: { id: idContent },
      });
    },
    resetFieldSearch() {
      this.slagSelect = [];
      const formData = {
        page: 1,
        data: {
          shop_id: this.shop_id,
          limit: this.limit,
          slag_id: [],
        },
      };
      this.getListContent(formData);
    },
  },
};
</script>
<style lang="scss" scoped>
.title-content-list {
  &-item {
    border: 2px solid #4a4a4a;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 30px;
    .header-item {
      padding: 0 50px 0 30px;
      &-title {
        font-size: 20px;
        color: #4a4a4a;
        font-weight: 600;
        @media (max-width: 768px) {
          min-width: unset !important;
        }
      }
      &-time {
        font-size: 16px;
        color: #000;
        @media (max-width: 768px) {
          padding: 0;
        }
      }
      @media (max-width: 768px) {
        padding: unset !important;
      }
    }
    .content-item {
      padding: 0 29px;
      margin: 20px 0;
      font-size: 14px;
      @media (max-width: 767px) {
        padding: 0;
      }
    }
    .list-tags {
      &-item {
        border: 2px solid #af5a21;
        background: #ed7d30;
        color: white;
        padding: 5px 20px;
        border-radius: 10px;
        margin-left: 20px;
        margin-bottom: 20px;
      }
    }
  }
}
.customContent {
  word-break: break-all;
  margin-left: 20px;
  min-width: 400px;
  max-width: 1000px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 768px) {
    margin-left: unset;
  }
}
.customChapter {
  word-break: break-all;
  margin-left: 50px;
  font-weight: 400 !important;
  min-width: 371px;
  max-width: 972px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 768px) {
    margin-left: unset;
  }
}
</style>
